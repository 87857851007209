@import url('https://fonts.googleapis.com/css?family=Montserrat|Pinyon+Script&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

.App{
  background-image: url("./images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  font-family: Pinyon,Montserrat, sans-serif;
  overflow: hidden;
}


img{
  display: block;
  width: 15rem;
  height: 5rem;
  margin: 7rem auto 1rem auto; 
}
.react{
  margin-top: 5rem;
}
.node{
  margin-top: 1rem;
}
@media only screen and (max-width: 680px) {
  .react{
    font-size: 26px;
  }
  .node{
    padding: 0 1rem 0 1rem;
  }
}